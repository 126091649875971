import { AttendanceType } from './attendanceType.enum';
import { Range } from './date-picker.model';
import { DateRange } from './academic-calendar.model';
import { CourseType } from './courseType.enum';
import { MeetingType } from './meetingType.enum';
import { ColorPalette } from './color-palette.model';
import { Meeting, MeetingStatusType, Subscriber } from './meetings-model.model';

import { AttendanceInfo } from './course-student-attendance.model';

export enum EventType {
    COURSE = 'COURSE',
    MEETING = 'MEETING',
    CUSTOM = 'CUSTOM',
}

export enum EventTopic {
    MeetingTopic = 'MeetingTopic',
    CourseTopic = 'CourseTopic',
    CustomTopic = 'CustomTopic',
}

export enum EventStatus {
    PUBLISHED = 'PUBLISHED',
    ENDED = 'ENDED',
    STARTED = 'STARTED',
    CANCELLED = 'CANCELLED',
}

export class CalendarEvent {
    constructor(
        public id?: string,
        public eventDate?: Date,
        public eventStartTime?: string,
        public eventEndTime?: string,
        public type?: EventType,
        public eventTitle?: string,
        public description?: string,
        public url?: string,
        public eventLocation?: string,
        public referenceId?: string,
        public meetingId?: string,
        public eventTopic?: EventTopic,
        public css?: ColorPalette,
        public meetingStatus?: MeetingStatusType,
        public schoolShortName?: string,
        public createdBy?: string,
        public currentUserAttendance?: AttendanceInfo,
        public courseType?: CourseType,
        public meetingType?: MeetingType,
        public attendanceInfo?: AttendanceInfo[],
        public enableAttendance?: boolean,
        public courseId?: string,
        public periodShortName?: string,
        public custom?: boolean,
        public currentUserDailyAttendance?: AttendanceType,
        public dailyNote?: string,
        public members?: Subscriber[]
    ) {
        this.id = id ? id : null;
        this.eventDate = eventDate ? eventDate : null;
        this.eventStartTime = eventStartTime ? eventStartTime : null;
        this.eventEndTime = eventEndTime ? eventEndTime : null;
        this.type = type ? type : null;
        this.eventTitle = eventTitle ? eventTitle : '';
        this.description = description ? description : '';
        this.url = url ? url : '';
        this.eventLocation = eventLocation ? eventLocation : '';
        this.referenceId = referenceId ? referenceId : '';
        this.meetingId = meetingId ? meetingId : '';
        this.eventTopic = eventTopic ? eventTopic : null;
        this.css = css ? css : { name: 'Default', value: '#fff', foreground: '#000' };
        this.meetingStatus = meetingStatus ? meetingStatus : MeetingStatusType.CREATED;
        this.schoolShortName = schoolShortName ? schoolShortName : null;
        this.createdBy = createdBy ? createdBy : '';
        this.currentUserAttendance = currentUserAttendance ? currentUserAttendance : null;
        this.attendanceInfo = attendanceInfo ? attendanceInfo : [];
        this.courseType = courseType ? courseType : CourseType.OFFLINE;
        this.meetingType = meetingType ? meetingType : null;
        this.enableAttendance = enableAttendance ? enableAttendance : true;
        this.courseId = courseId ? courseId : '';
        this.periodShortName = periodShortName ? periodShortName : '';
        this.custom = custom ? custom : false;
        this.currentUserDailyAttendance = currentUserDailyAttendance ? currentUserDailyAttendance : null;
        this.dailyNote = dailyNote ? dailyNote : '';
        this.members = members ?? [];
    }
}

export class CalendarEventSearch {
    constructor(
        public eventDate?: Date,
        public schoolId?: string,
        public entityId?: string,
        public studentUserId?: string, // use for attendance info
        public courseId?: string,
        public timeZoneOffet?: number,
        public schoolTimeZoneOffset?: number,
        public dateRange?: DateRange,
        public calculateAttendance?: boolean
    ) {
        this.eventDate = eventDate ? eventDate : null;
        this.schoolId = schoolId ? schoolId : null;
        this.entityId = entityId ? entityId : null;
        this.studentUserId = studentUserId ? studentUserId : null;
        this.courseId = courseId ? courseId : null;
        this.timeZoneOffet = new Date().getTimezoneOffset() * -1;
        this.schoolTimeZoneOffset = schoolTimeZoneOffset ?? 0;
        this.dateRange = dateRange ?? null;
        this.calculateAttendance = calculateAttendance ?? false;
    }
}

export class ModeratorCourseEventsSearch {
    constructor(
        public dateRange?: Range,
        public schoolId?: string,
        public moderatorId?: string,
        public courseId?: string
    ) {
        this.dateRange = dateRange ? dateRange : null;
        this.schoolId = schoolId ? schoolId : null;
        this.moderatorId = moderatorId ? moderatorId : null;
        this.courseId = courseId ? courseId : null;
    }
}

export class CalendarEventMessage {
    constructor(
        public title?: string,
        public fromUser?: string,
        public message?: string,
        public isModerator?: boolean,
        public to?: Subscriber[]
    ) {
        this.title = title ? title : null;
        this.fromUser = fromUser ? fromUser : null;
        this.message = message ? message : null;
        this.isModerator = isModerator ? isModerator : false;
        this.to = to ? to : null;
    }
}

export class RecentMeeting {
    constructor(
        public id?: string,
        public eventDate?: Date,
        public eventStartTime?: string,
        public eventEndTime?: string,
        public eventTitle?: string,
        public status?: string,
        public moderatorName?: string,
        public meeting?: Meeting,
        public isAttended?: boolean,
        public courseId?: string,
        public allowAttendeeWatchRecording?: boolean
    ) {
        this.id = id ? id : null;
        this.eventDate = eventDate ? eventDate : null;
        this.eventStartTime = eventStartTime ? eventStartTime : null;
        this.eventEndTime = eventEndTime ? eventEndTime : null;
        this.eventTitle = eventTitle ? eventTitle : '';
        this.moderatorName = moderatorName ? moderatorName : '';
        this.meeting = meeting ? meeting : null;
        this.isAttended = isAttended ? isAttended : false;
        this.courseId = courseId ? courseId : null;
        this.allowAttendeeWatchRecording = allowAttendeeWatchRecording ? allowAttendeeWatchRecording : false;
        this.status = status ? status : null;
    }
}

export class CustomCalendarEvent {
    constructor(
        public id?: string,
        public eventDate?: Date,
        public eventStartTime?: string,
        public eventEndTime?: string,
        public eventTitle?: string,
        public description?: string,
        public schoolId?: string,
        public attendees?: Subscriber[]
    ) {
        this.id = id ? id : null;
        this.eventDate = eventDate ? eventDate : null;
        this.eventStartTime = eventStartTime ? eventStartTime : null;
        this.eventEndTime = eventEndTime ? eventEndTime : null;
        this.eventTitle = eventTitle ? eventTitle : '';
        this.description = description ? description : '';
        this.schoolId = schoolId ? schoolId : null;
        this.attendees = attendees ? attendees : [];
    }
}

export class IPageableCalendarEvent {
    public id?: string;
    public eventDate?: Date;
    public eventStartTime?: string;
    public eventEndTime?: string;
    public type?: EventType;
    public eventTitle?: string;
    public description?: string;
    public url?: string;
    public eventLocation?: string;
    public referenceId?: string;
    public meetingId?: string;
    public recordingReady?: boolean;
    public eventTopic?: EventTopic;
    public css?: ColorPalette;
    public meetingStatus?: string;
    public schoolShortName?: string;
    public createdBy?: string;
    public status?: EventStatus;
    public courseId?: string;
    public attendanceInfo?: AttendanceInfo[];
}
